import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'button' ]

  copy(event) {
    event.preventDefault()

    var textArea = document.createElement('textarea')

    textArea.style.position = 'fixed'
    textArea.style.top = 0
    textArea.style.left = 0
    textArea.style.width = '2em'
    textArea.style.height = '2em'
    textArea.style.padding = 0
    textArea.style.border = 'none'
    textArea.style.outline = 'none'
    textArea.style.boxShadow = 'none'
    textArea.style.background = 'transparent'

    textArea.value = this.buttonTarget.dataset.text

    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand('copy')
    document.body.removeChild(textArea)

    var button = this.buttonTarget
    var originalLabel = button.value

    button.value = 'Copied! 🎉'

    setTimeout(function() {
      button.value = originalLabel
    }, 1500)
  }
}
